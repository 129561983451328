

















































































































import { mapActions, mapGetters, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { createMobileEvent, editMobileEvent } from '@/api/MobileOperator';
import { MobileEventStatus } from '@/api/schema';
import type { MobileEventItem, NewMobileEvent } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 5,
  sortBy: ['id'],
  sortDesc: [true]
};

export default {
  name: 'ViewPlayToEarnEvents',
  data: (): unknown => ({
    event: {
      name: '',
      reward: null,
      status: MobileEventStatus.deactivated
    } as NewMobileEvent,
    status: [MobileEventStatus.active, MobileEventStatus.deactivated],
    formValid: true,
    editFormValid: true,
    rules: {
      eventName: [(v) => vr.required(v, 'Event name')],
      eventReward: [(v) => vr.required(v, 'Event reward')]
    },
    options: { ...defaultOptions },
    showDialog: false,
    selectedItem: {
      name: '',
      reward: null,
      status: MobileEventStatus.deactivated,
      count: 0,
      id: 0,
      totalReward: 0
    } as MobileEventItem,
    sort: 'id,desc'
  }),
  computed: {
    ...mapState('MobileOperator', ['mobileEvents']),
    ...mapGetters('Onboarding', ['operatorId', 'onboardingForm']),
    headers(): { [key: string]: unknown }[] {
      return [
        { text: 'Id', value: 'id', sortable: true },
        { text: 'Event Name', value: 'name', sortable: true },
        {
          text: `Reward Amount (${this.onboardingForm.tokenTicker})`,
          value: 'reward',
          sortable: true
        },
        { text: 'Count', value: 'count', sortable: true },
        { text: 'Total Reward', value: 'totalRewarded', sortable: true },
        { text: 'Status', value: 'status', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ];
    },
    currentPage(): number {
      return this.options.page - 1;
    }
  },
  watch: {
    options: {
      async handler(): Promise<void> {
        const field = this.options.sortBy?.[0] || 'id';
        const desc = this.options.sortDesc?.[0] ? ',desc' : '';
        this.sort = field + desc;

        if (this.operatorId) {
          await this.getMobileEvents({
            page: this.currentPage,
            size: this.options.itemsPerPage,
            sort: this.sort
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('MobileOperator', ['getMobileEvents']),
    handlerAddEvent(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formValid) {
          this.createMobileEvent();
        }
      });
    },
    createMobileEvent(): void {
      createMobileEvent(this.event)
        .then(() => {
          this.$toast.success('Event has been added');
          this.getMobileEvents({
            page: this.currentPage,
            size: this.options.itemsPerPage,
            sort: this.sort
          });
        })
        .catch((e) => {
          this.$toast.error(e.messages);
        })
        .finally(() => {
          this.event = {
            name: '',
            reward: null,
            status: MobileEventStatus.deactivated
          };
          this.$refs.form.reset();
        });
    },
    handlerOpenEditDialog(item: MobileEventItem): void {
      this.selectedItem = { ...item };
      this.showDialog = true;
    },
    handlerUpdateEvent(): void {
      const form = this.$refs.editForm;
      form.validate();

      this.$nextTick((): void => {
        if (this.editFormValid) {
          this.updateEvent();
        }
      });
    },
    updateEvent(): void {
      const { id, name, reward, status } = this.selectedItem;

      editMobileEvent(id, { name, reward, status })
        .then(() => {
          this.$toast.success('Event has updated');
          this.getMobileEvents({
            page: this.currentPage,
            size: this.options.itemsPerPage,
            sort: this.sort
          });
        })
        .catch((e) => {
          this.$toast.error('Something was wrong', e.messages);
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    handlerChangeStatus(
      status: MobileEventStatus,
      event: MobileEventItem
    ): void {
      const { id, name, reward } = event;

      editMobileEvent(id, { name, reward, status })
        .then(() => {
          const messages =
            status === 'ACTIVE'
              ? `Event ${name} has been activated`
              : `Event ${name} has been deactivated`;
          this.$toast.success(messages);
          this.getMobileEvents({
            page: this.currentPage,
            size: this.options.itemsPerPage,
            sort: this.sort
          });
        })
        .catch((e) => {
          this.$toast.error('Something was wrong', e.messages);
        })
        .finally(() => {
          this.showDialog = false;
        });
    }
  }
};
